import React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} round {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,

  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        style={{ color: "black" }}
        sx={{ fontSize: "0.9rem" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  color: "black",

  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "black",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    color: "black",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    color: "black",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgb(255 161 0)",
  color: "black",
  backgroundColor: "white",
}));

export default function FAQsSection() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <section id="FAQ-microREC">
      <Box
        sx={{
          paddingTop: 0,
          paddingBottom: 10,
          marginTop: 1,
        }}
      >
        <Container maxWidth={"lg"}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    Do you provide a warranty?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    After delivery, if you are not satisfied with the product,
                    you have 14 days to claim a full refund of the purchase (no
                    questions asked).
                    <Typography sx={{ padding: 1 }}></Typography> Additionally,
                    all manufacturing issues reported within the first 2 years
                    will entitle for a replacement or a fix at no extra cost.{" "}
                    <Typography sx={{ padding: 1 }}></Typography>Check all the
                    information{" "}
                    <a className="links_post" href="/warranty">
                      here
                    </a>
                    .
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel3bh-header"
                  className="AccordionSummary2"
                >
                  <Typography
                    sx={{ flexShrink: 1 }}
                    className="AccordionSummary2"
                  >
                    What are the terms of your warranty?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails id="" className="AccordionSummary2">
                  <Typography sx={{ padding: 1 }}>
                    You can check out our warranty terms and conditions on{" "}
                    <a href="/warranty" className="links_post">
                      this page
                    </a>
                    .
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography
                    sx={{ flexShrink: 1 }}
                    className="AccordionSummary3"
                  >
                    How to refund my purchase or claim the warranty?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="AccordionSummary3">
                  <Typography sx={{ padding: 1 }}>
                    Make sure you have the following things before you claim
                    your warranty:<Typography sx={{ padding: 1 }}></Typography>{" "}
                    1. Warranty activation card{" "}
                    <Typography sx={{ padding: 1 }}></Typography>2. Original
                    packaging<Typography sx={{ padding: 1 }}></Typography> 3.
                    The receipt of your purchase{" "}
                    <b>
                      If you purchased the product through the authorized
                      distributor
                    </b>
                    <Typography sx={{ padding: 1 }}></Typography> Please contact
                    your distributor and follow their instructions. You can ask
                    as about the contact information for the distributor via
                    Whatsapp{" "}
                    <a
                      href="https://wa.me/4915234689005?text=Hello,%20I%20came%20from%20the%20website,%20and%20I%20want%20to%20know%20more%20about"
                      target="t_blank"
                      className="links_post"
                    >
                      here
                    </a>
                    .<Typography sx={{ padding: 1 }}></Typography>{" "}
                    <b>
                      If you purchased the product through customsurgical.co
                    </b>
                    <Typography sx={{ padding: 1 }}></Typography> Please contact
                    us at{" "}
                    <a
                      href="mailto:support@customsurgical.co"
                      className="links_post"
                    >
                      support@customsurgical.co
                    </a>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    Do I have to sterilize the equipment?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    Our optical systems do not get in direct contact with a
                    patient, and therefore, they do not require sterilization.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  );
}
